```vue
<template>
  <a-card :title="$t(title)" v-if="high_priority_highlights.length > 0">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-hightlight-item
          v-for="(highlight, index) in high_priority_highlights"
          :key="'highlight_' + index"
          :hightlight="highlight"
          :clas="'ml-3'"
          @loadRate="loadRate"
          @loadParity="loadParity"
        ></a-hightlight-item>
        <v-btn
          outlined
          rounded
          color="info"
          @click="seeDailyHighlight"
          class="mt-2 mb-1 ml-3"
          v-if="review_button"
          >{{ $t("to_review") }}</v-btn
        >
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
      </template>
    </a-base-component>
  </a-card>
  <a-card :title="$t(title)" v-else>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-card-subtitle class="px-3 pt-4 pb-6 text-center">
          <v-icon color="grey lighten-2" class="display-4"
            >mdi-check-bold</v-icon
          ><br />
          {{ $t("nothing_to_review") }}
        </v-card-subtitle>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aHightlightItem from "@/components/aHightlightItem";
import { mapGetters, mapState } from "vuex";

export default {
  name: "a-highlights-resume",
  components: {
    aBaseComponent,
    aCard,
    aHightlightItem
  },
  props: {
    review_button: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      subscribed_modules: [
        "rate_available_rate_extractions",
        "highlight_list",
        "highlight_info",
        "bi_report",
        "parity_webs"
      ],
      title: "we_suggest_you_to_review"
    };
  },
  computed: {
    high_priority_highlights() {
      return this.highlights
        .filter(highlight => !this.isDone(highlight))
        .sort((a, b) => b.priority - a.priority)
        .slice(0, 4);
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    ...mapState({
      highlights: state => state.highlight.list
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getHotelById: "getHotelById"
    })
  },
  methods: {
    loadRate(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    loadParity(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "parity",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    seeDailyHighlight() {
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: this.current_hotel.settings.slug
        },
        query: {
          highlights: true
        }
      });
    },
    isDone(highlight) {
      return !!(
        this.current_user.settings &&
        this.current_user.settings.highlights_done &&
        this.current_user.settings.highlights_done.indexOf(highlight.id) >= 0
      );
    },
    seeHighlight(highlight) {
      //We use 'user' for dispatching when this.current_user = user;
      const user = JSON.parse(JSON.stringify(this.current_user));

      //We verify it exists and it's an array
      const highlights_done =
        this.current_user.settings && this.current_user.settings.highlights_done
          ? JSON.parse(
              JSON.stringify(this.current_user.settings.highlights_done)
            )
          : [];
      if (Object.keys(user.settings).length === 0) {
        user.settings = {};
      }
      if (highlights_done.indexOf(highlight.id) < 0) {
        // We add it
        highlights_done.push(highlight.id);
        user.settings.highlights_done = highlights_done;
      } else {
        // We remove it
        highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
        user.settings.highlights_done = highlights_done;
      }

      // We update it here, for UX improve
      this.current_user = user;

      // We save it in the server, in background (no waiting time for user)
      this.$store.dispatch("updateUser", this.current_user);
    }
  }
};
</script>

<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="dashboard_rate">
    <v-row v-if="highlights_info" class="mt-n4">
      <v-col cols="12" lg="6" class="pt-0">
        <a-parity-dashboard-resume
          v-if="show_rate_web || show_parity"
          ref="a-parity-dashboard-resume"
        >
        </a-parity-dashboard-resume>
        <a-rate-dashboard-resume v-else ref="a-rate-dashboard-resume">
        </a-rate-dashboard-resume>
      </v-col>
      <v-col cols="12" lg="6" class="pt-0">
        <a-rate-ranking-next-days
          ref="a-rate-ranking-next-days"
          v-if="show_reputation"
        >
        </a-rate-ranking-next-days>
        <a-highlights-resume
          ref="a-highlights-resume"
          :review_button="false"
          v-else
        ></a-highlights-resume>
      </v-col>
    </v-row>
    <v-row v-if="show_reputation">
      <v-col cols="12" lg="6">
        <a-highlights-resume ref="a-highlights-resume"></a-highlights-resume>
      </v-col>
      <v-col cols="12" lg="6">
        <a-reputation-dashboard-resume ref="a-reputation-dashboard-resume">
        </a-reputation-dashboard-resume>
      </v-col>
    </v-row>
    <v-row v-else>
      <a-rate-ranking-next-days
        :extended="true"
        ref="a-rate-ranking-next-days"
      ></a-rate-ranking-next-days>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aReputationDashboardResume from "@/components/reputation/aReputationDashboardResume";
import aParityDashboardResume from "@/components/parity/aParityDashboardResume";
import aRateDashboardResume from "@/components/rate/aRateDashboardResume";
import aRateRankingNextDays from "@/components/rate/aRateRankingNextDays";
import aHighlightsResume from "@/components/highlights/aHighlightsResume.vue";
var numeral = require("numeral");

export default {
  name: "dashboard-rate-app",
  components: {
    aHighlightsResume,
    aReputationDashboardResume,
    aParityDashboardResume,
    aRateDashboardResume,
    aRateRankingNextDays
  },
  data: vm => ({
    page: 1,
    perPage: 8,
    init_date: null,
    subscribed_modules: [
      "rate_available_rate_extractions",
      "highlight_list",
      "highlight_info",
      "bi_report",
      "parity_webs"
    ],
    title: "we_suggest_you_to_review"
  }),
  watch: {
    available_rate_extractions() {
      if (!this.extraction_id) {
        this.setDefault();
      }
    }
  },
  methods: {
    setDefault() {
      /* CHECK EACH PARAM */
      this.extraction_id =
        this.query_params.extraction_id &&
        this.extractionAllowedId(this.query_params.extraction_id)
          ? this.query_params.extraction_id
          : this.defaultExtractionId();

      this.init_date =
        this.query_params.init_date &&
        this.initAllowedDate(this.query_params.init_date)
          ? this.query_params.init_date
          : this.defaultInitDate();

      let vs_id =
        this.query_params.vs_id && this.vsAllowedId(this.query_params.vs_id)
          ? this.query_params.vs_id
          : this.defaultVsId();

      this.mode = "rate";

      let adults =
        this.query_params.adults &&
        [0, 2, 3, 4, 5, 6, 7].indexOf(parseInt(this.query_params.adults)) >= 0
          ? parseInt(this.query_params.adults)
          : 2;

      let childs =
        this.query_params.childs &&
        [0, 1, 2].indexOf(parseInt(this.query_params.childs)) >= 0
          ? parseInt(this.query_params.childs)
          : 0;

      let regime = this.query_params.regime
        ? JSON.parse(this.query_params.regime.slice(1, -1))
        : [];

      let cancel_policy = this.query_params.cancel_policy
        ? JSON.parse(this.query_params.cancel_policy.slice(1, -1))
        : [];

      if (this.query_params.highlights == "true") {
        this.showRateHighlight(true);
      }
      this.$store.dispatch("setRateFilters", {
        extraction_id: this.extraction_id,
        vs_id: vs_id,
        pax: adults,
        childs: childs,
        regime: regime,
        cancel_policy: cancel_policy
      });

      this.$store.dispatch("setHighlightsExtractionId", this.extraction_id);
    },
    // FILTERS
    extractionAllowedId(v) {
      return !!(
        this.available_rate_import_ids &&
        this.available_rate_import_ids.find(id => id == v)
      );
    },
    defaultExtractionId() {
      return this.available_rate_import_ids
        ? this.available_rate_import_ids[0]
        : "";
    },
    initAllowedDate(v) {
      // Must return if equal or bigger than selected extraction_date
      var date = Vue.moment(v);
      var extraction_date = Vue.moment(this.extraction_date);
      return date.diff(extraction_date, "days") >= 0;
    },
    defaultInitDate() {
      return this.extraction_date;
    },
    vsAllowedId(v) {
      // Must return if lower than selected extraction_id and extractionAllowedId(v)
      return !!(v < this.extraction_id && this.extractionAllowedId(v));
    },
    defaultVsId() {
      // Must return max from available_rate_import_ids and lower than extraction_id
      var vm = this;
      return this.available_rate_import_ids
        ? this.available_rate_import_ids.find(id => vm.vsAllowedId(id))
        : "";
    },
    formatDate(date) {
      return date
        ? Vue.moment(date)
            .format("D MMM YYYY")
            .replace(".", "")
            .toUpperCase()
        : "";
    },
    ...mapActions(["showRateHighlight"])
  },
  mounted() {
    this.$nextTick(function() {
      if (this.current_hotel) {
        this.setDefault();
      }
    });
  },
  computed: {
    booking() {
      return this.get_ota("booking");
    },
    extraction_id: {
      get() {
        return this.$store.state.extraction_id;
      },
      set(value) {
        this.$store.dispatch("setExtractionId", value);
      }
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    highlight_type_pages() {
      var pages = [];
      if (this.highlights_by_type) {
        for (var i = 0; i < this.highlights_by_type.length; i++) {
          pages.push(i);
        }
      }
      return pages;
    },
    highlight_types() {
      return this.highlights_info ? this.highlights_info.highlight_types : [];
    },
    available_rate_import_ids() {
      // In this.current_hotel.settings.available_rate_extractions we have a list of [{date: "2020-01-01", extractions: {id: 1234, time: "12:01:01"}}, {...}]
      // From there we take a list of ids to make an easy validation
      let ids = [];
      if (this.available_rate_extractions) {
        ids = this.available_rate_extractions.reduce(
          (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
          []
        );
      }
      return ids;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    show_parity() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.parity &&
            this.current_hotel.permissions.parity
        : false;
    },
    show_reputation() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.reputation &&
            this.current_hotel.permissions.reputation
        : false;
    },
    ...mapState({
      highlights: state => state.highlight.list,
      highlights_info: state => state.highlight.info,
      priorities: state => state.highlight_priorities,
      query_params: state => state.route.query,
      daily_rates: state => state.rate.daily_rates,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      getHighlightTypes: "getHighlightTypes",
      getHighlightsByType: "getHighlightsByType",
      current_hotel: "current_hotel",
      getHotelById: "getHotelById",
      highlights_by_priority: "highlights_by_priority",
      highlights_by_type: "highlights_by_type",
      get_ota: "get_ota"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
@media (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 46px !important;
    padding: 0 5px !important;
  }
}
</style>
